<template>
    <div class="ml-6 mr-6">
        <v-row justify="center">
            <h1>Profil</h1>
        </v-row>
        <v-row justify="center">
            <v-col>

            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col>
                <p class="text-paragraph">
                    Brugernavn: <span>{{ userAttributes.email }}</span>
                </p>
                <h3>Aktive abonnementer</h3>
                <p class="text-paragraph">
                <ul>
                    <li v-for="subscription in subscriptions" v-bind:key="subscription.product_type">
                        {{ subscription.description }},
                        <span v-if="subscription.automatically_renews">næste betaling: </span>
                        <span v-if="!subscription.automatically_renews">udløber </span>
                        {{ formatDateTime(subscription.period_end) }}
                    </li>
                    <li v-if="subscriptions.length === 0">Ingen aktive abonnementer</li>
                </ul>
                </p>
                <h3>Forespørgsler de seneste 6 måneder</h3>
                <p class="text-paragraph">
                <ul>
                    <li v-for="monthRequest in requestCounts" v-bind:key="monthRequest.month">
                        {{ formatDateMonth(monthRequest.month) }}: {{ monthRequest.requests.toLocaleString() }}
                    </li>
                    <li v-if="requestCounts.length === 0">Ingen forespørgsler</li>
                </ul>
                </p>

                <div v-if="hasActiveSubscription">
                    <v-btn v-if="hasHadTrial" :loading="updateSubscriptionLoading" class="mt-4" color="accent" dark
                        v-bind="attrs" @click="updateSubscription()">
                        Administrer abonnement
                    </v-btn>

                    <router-link v-if="!hasHadTrial" class="no-decoration" :to="{ name: 'prices' }">
                        <v-btn class="mt-4" color="accent" dark v-bind="attrs">
                            Påbegynd 30 dages prøveperiode
                        </v-btn>
                    </router-link>
                </div>

                <div v-if="!hasActiveSubscription">
                    Du skal have et aktivt abonnement for at kunne oprette dine API keys. <br />
                    <router-link :to="{ name: 'prices' }" class="no-decoration">
                        <v-btn v-if="hasHadTrial" class="mt-4" color="accent" dark v-bind="attrs">
                            Påbegynd abonnement
                        </v-btn>
                        <v-btn v-if="!hasHadTrial" class="mt-4" color="accent" dark v-bind="attrs">
                            Prøv 30 dage gratis
                        </v-btn>
                    </router-link>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-divider class="mt-8 mb-8"></v-divider>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-8">
            <h1>API keys</h1>
        </v-row>
        <v-row justify="center">
            <v-col>
                <p class="text-paragraph">
                    Du skal have en API key for at kunne benytte cvr.dev. Vores
                    <a rel="noopener noreferrer" target="_blank" :href="docsUrl">dokumentation</a>
                    indeholder information om hvordan API keys skal benyttes.<br /><br />
                    Du er velkommen til at kontakte os på
                    <a rel="noopener noreferrer" target="_blank" href="mailto:kontakt@cvr.dev">kontakt@cvr.dev</a>
                    hvis du har spørgsmål eller kommentarer!
                </p>
            </v-col>
        </v-row>
        <v-dialog v-model="createApiKeyDialog" persistent max-width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" :disabled="!hasActiveSubscription" dark v-bind="attrs" v-on="on">
                    Opret API key
                </v-btn>
            </template>
            <v-form ref="apiKeyForm" v-model="apiKeyForm" @keyup.native.enter="submit" @submit.prevent>
                <v-card>
                    <v-card-title class="headline"> API key </v-card-title>
                    <v-card-text>
                        Giv din api key et sigende navn for det miljø du vil bruge den i,
                        eksempelvis "din-app, produktion".
                        <v-spacer></v-spacer>
                        <v-container fluid>
                            <v-row>
                                <v-text-field autofocus v-model="apiKeyName" label="Navn" name="name" persistent-hint />
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" text @click="submit"> Opret </v-btn>
                        <v-btn color="normal" text @click="createApiKeyDialog = false">
                            Annuller
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <SecretCopyDialog title="API key" description="Gem denne API key et sikkert sted. Den bliver kun vist én gang!"
            :secret="apiKeyKey" :show="showApiKeyDialog" @click-close="showApiKeyDialog = false" />

        <v-row justify="center">
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Navn</th>
                                <th class="text-left">Oprettet</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in apiKeys" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.created_at }}</td>
                                <td class="text-right">
                                    <v-icon @click="deleteApiKey(item.id, item.name)">mdi-trash-can-outline</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col>
                <p></p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import BackendClient from "@/clients/backend";
import SecretCopyDialog from "@/components/SecretCopyDialog";
import { mapMutations, mapGetters } from "vuex";
import config from "@/config";

export default {
    computed: {
        ...mapGetters("cognito", ["userAttributes"]),

        hasActiveSubscription() {
            return this.subscriptions.length > 0;
        }
    },
    components: {
        SecretCopyDialog,
    },

    data() {
        return {
            docsUrl: config.DocsUrl,
            apiClientPython: config.APIClientPython,
            apiClientGo: config.APIClientGo,
            backendClient: null,
            apiKeys: [],
            subscriptions: [],
            requestCounts: [],
            mountedDone: false,

            updateSubscriptionLoading: false,

            createApiKeyDialog: false,
            showApiKeyDialog: false,
            apiKeyForm: false,
            apiKeyName: undefined,

            apiKeyKey: undefined,
        };
    },

    async mounted() {
        this.backendClient = new BackendClient(this.$store);

        await Promise.all([
            this.fetchProductAccessPeriods(),
            this.fetchApiKeys(),
            this.fetchRequestCounts(),
        ]);
    },

    methods: {
        ...mapMutations(["setSnackbar"]),

        formatDateTime(dateStr) {
            const date = new Date(dateStr)
            return `${date.toLocaleDateString()} kl. ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        },

        formatDateMonth(dateStr) {
            const date = new Date(dateStr)
            return date.toLocaleString('default', { month: 'long', year: 'numeric' });
        },

        async submit() {
            try {
                const keyName = this.apiKeyName;
                this.apiKeyKey = await this.backendClient.createApiKey(this.apiKeyName);

                this.createApiKeyDialog = false;
                this.showApiKeyDialog = true;
                this.apiKeyName = '';

                this.setSnackbar({
                    type: "success",
                    msg: `API key ${keyName} er tilføjet`,
                    timeout: 5000,
                });

                await this.fetchApiKeys();
            } catch (err) {
                this.setSnackbar({
                    type: "error",
                    msg: err.message,
                    timeout: 10000,
                });
            }
        },

        async deleteApiKey(id, name) {
            try {
                await this.backendClient.deleteApiKey(id);
                this.setSnackbar({
                    type: "success",
                    msg: `API key ${name} er slettet`,
                    timeout: 5000,
                });

                await this.fetchApiKeys();
            } catch (err) {
                this.setSnackbar({
                    type: "error",
                    msg: err.message,
                    timeout: 10000,
                });
            }
        },

        async fetchProductAccessPeriods() {
            const output = await this.backendClient.listActiveProductAccessPeriods();
            this.subscriptions = output.active_product_access_periods;
            this.hasHadTrial = output.has_had_trial;
        },

        async fetchApiKeys() {
            this.apiKeys = await this.backendClient.listApiKeys();
        },

        async fetchRequestCounts() {
            this.requestCounts = await this.backendClient.listRequestCounts();
            console.log(this.requestCounts);
        },

        async updateSubscription() {
            this.updateSubscriptionLoading = true;
            const portalSessionLink = await this.backendClient.createCustomerPortalSession();

            this.updateSubscriptionLoading = false;
            window.location.href = portalSessionLink;
        },
    },
};
</script>

<style>
.text-paragraph {
    max-width: 800px;
    min-width: 300px;
}

.no-decoration {
    text-decoration: none;
}
</style>
